// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-layouts-blog-blog-post-js": () => import("./../../../src/layouts/blog/BlogPost.js" /* webpackChunkName: "component---src-layouts-blog-blog-post-js" */),
  "component---src-layouts-tags-tags-detail-js": () => import("./../../../src/layouts/tags/TagsDetail.js" /* webpackChunkName: "component---src-layouts-tags-tags-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-demos-glasses-js": () => import("./../../../src/pages/demos/glasses.js" /* webpackChunkName: "component---src-pages-demos-glasses-js" */),
  "component---src-pages-demos-nsfw-js": () => import("./../../../src/pages/demos/nsfw.js" /* webpackChunkName: "component---src-pages-demos-nsfw-js" */),
  "component---src-pages-demos-places-js": () => import("./../../../src/pages/demos/places.js" /* webpackChunkName: "component---src-pages-demos-places-js" */),
  "component---src-pages-demos-stylist-js": () => import("./../../../src/pages/demos/stylist.js" /* webpackChunkName: "component---src-pages-demos-stylist-js" */),
  "component---src-pages-faceradar-js": () => import("./../../../src/pages/faceradar.js" /* webpackChunkName: "component---src-pages-faceradar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-puzzlelib-js": () => import("./../../../src/pages/puzzlelib.js" /* webpackChunkName: "component---src-pages-puzzlelib-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

